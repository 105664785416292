import { lazy, Suspense } from "react";
import AuthGuard from "./helpers/AuthGuard";
import VendorGuard from "./helpers/VendorGuard";
import { GuestGuard, RootGuard } from "./helpers/GuestGuard";
import SemiGaurd from "./helpers/SemiGaurd";
import SignUpVendorDetails from "./user/Credential/SignUpVendorDetails";
import SignUpContactDetails from "./user/Credential/SignupContactDetails";
import LoaderComponent from "./vendor/Content/Loader/loader";

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoaderComponent loading={true} />}>
    <Component {...props} />
  </Suspense>
);


const Landing = Loadable(lazy(() => import("./user/Landing")));
// const Login = Loadable(lazy(() => import("./user/Credential/Login")));
const LoginV2 = Loadable(lazy(() => import("./user/Credential/LoginV2")));
const SignUpCustomer = Loadable(lazy(() => import("./user/Credential/SignUpCustomer")));
const Create_Account = Loadable(lazy(() => import("./user/Credential/Create_Account")));
const Forget_Password = Loadable(lazy(() => import("./user/Credential/Forget_Password")));
const ForgotPasswordV2 = Loadable(lazy(() => import("./user/Credential/ForgotPasswordV2")));
const OTP = Loadable(lazy(() => import("./user/Credential/OTP")));
const VerificationCode = Loadable(lazy(() => import("./user/Credential/VerificationCode")));
const Create_New_Password = Loadable(lazy(() => import("./user/Credential/Create_New_Password")));
const CreateNewPassword = Loadable(lazy(() => import("./user/Credential/CreateNewPassword")));
const SignUpCompanyDetails = Loadable(lazy(() => import("./user/Credential/SignUpCompanyDetails")));
const SignUpBankDetails = Loadable(lazy(() => import("./user/Credential/SignUpBankDetails")));
const SignUpItemDetails = Loadable(lazy(() => import("./user/Credential/SignUpItemDetails")));
// const SignUpItemDetails2 = Loadable(lazy(() => import("./user/Credential/SignUpItemDetails2")));


const MyEvent = Loadable(lazy(() => import("./user/MyEvent/Index")));
const Notification = Loadable(lazy(() => import("./user/Notification/index")));
const NotificationHistory = Loadable(lazy(() => import("./user/Notification/NotificationHistory")));
const PaymentMethod = Loadable(lazy(() => import("./user/Payment/index")));
const CartPage = Loadable(lazy(() => import("./user/Cart/CartPage")));
const PaymentPage = Loadable(lazy(() => import("./user/Payment/Payment")));
const FilterPage = Loadable(lazy(() => import("./user/Filter/index")));

const VendorLanding = Loadable(lazy(() => import("./vendor/Landing/index")));
const AddProduct = Loadable(lazy(() => import("./vendor/Content/ProductAndService/AddProducts")));
const Dashboard = Loadable(lazy(() => import("./vendor/Content/Dashboard/Dashboard")));

const Automate_Event = Loadable(lazy(() => import("./user/Automate_Event/index")));
const Event_list = Loadable(lazy(() => import("./user/Event_detail/Event_list")));
const Event_detail = Loadable(lazy(() => import("./user/Event_detail/index")));
const Profile = Loadable(lazy(() => import("./vendor/Content/Profile/index")));
const ContactDetails = Loadable(lazy(() => import("./vendor/Content/Profile/contact")));
const CoverageDetails = Loadable(lazy(() => import("./vendor/Content/Profile/coverageArea")));
const PaymentDetails = Loadable(lazy(() => import("./vendor/Content/PaymentManagement/PaymentDetails")));

const BankDetails = Loadable(lazy(() => import("./vendor/Content/Profile/bank")));
const PreviewAdd = Loadable(lazy(() => import("./vendor/Content/ProductAndService/Preview")));

const Hot_deals = Loadable(lazy(() => import("./user/Event_detail/Hot_deals")));
const SearchByCategory = Loadable(lazy(() => import("./user/SearchByCategory/index")));
const VendorDetail = Loadable(lazy(() => import("./user/SearchByCategory/VendorDetail")));
const CategoryDetail = Loadable(lazy(() => import("./user/SearchByCategory/CategoryDetail")));
const EditUserProfile = Loadable(lazy(() => import("./user/MyProfile/EditProfile")));
const EventDetail = Loadable(lazy(() => import("./user/MyEvent/components/subComponents/ViewEvent")));
const EventDetail_old = Loadable(lazy(() => import("./user/MyEvent/components/subComponents/ViewEvent_old")));
const MyAttendees = Loadable(lazy(() => import("./user/MyEvent/components/subComponents/MyAttendees")));
const Invitation = Loadable(lazy(() => import("./user/MyEvent/components/subComponents/Invitation")));
const UserAddress = Loadable(lazy(() => import("./user/Address")));
const CustomerCare = Loadable(lazy(() => import("./user/MyProfile/CustomerCare")));
const VendorCustomerCare = Loadable(lazy(() => import("./user/MyProfile/VendorCustomerCare")));


const AboutUs = Loadable(lazy(() => import("./user/MyProfile/AboutUs")));

const routes = [
  {
    path: "/",
    element: (<RootGuard><Landing /></RootGuard>),
  },
  // {
  //   path: "/login",
  //   element: (<GuestGuard><Login /></GuestGuard>),
  // },
  {
    path: "/loginv2",
    element: (<GuestGuard><LoginV2 /></GuestGuard>),
  },
  {
    path: "/signup",
    element: (<GuestGuard><SignUpCustomer /></GuestGuard>),
  },
  {
    path: "/create_account",
    // eslint-disable-next-line
    element: (<GuestGuard><Create_Account /></GuestGuard>),
  },
  {
    path: "/forget_password",
    // eslint-disable-next-line
    element: (<GuestGuard><Forget_Password /></GuestGuard>),
  },
  {
    path: "/forgot-passwordv2",
    element: (<GuestGuard><ForgotPasswordV2 /></GuestGuard>),
  },
  {
    path: "/otp",
    element: (<SemiGaurd><OTP /></SemiGaurd>),
  },
  {
    path: "/verification-code",
    element: (<GuestGuard><VerificationCode /></GuestGuard>),
  },
  {
    path: "/create_new_password",
    // eslint-disable-next-line
    element: (<SemiGaurd><Create_New_Password /></SemiGaurd>),
  },
  {
    path: "/create-new-password",
    element: (<GuestGuard><CreateNewPassword /></GuestGuard>),
  },
  {
    path: "/signup-vendor-details",
    element: (<GuestGuard><SignUpVendorDetails /></GuestGuard>),
  },
  {
    path: "/signup-company-details",
    element: (<GuestGuard><SignUpCompanyDetails /></GuestGuard>),
  },
  {
    path: "/signup-contact-details",
    element: (<GuestGuard><SignUpContactDetails /></GuestGuard>),
  },
  {
    path: "/signup-bank-details",
    element: (<GuestGuard><SignUpBankDetails /></GuestGuard>),
  },
  {
    path: "/signup-item-details",
    element: (<GuestGuard><SignUpItemDetails /></GuestGuard>),
  },
  // {
  //   path: "/signup-item-details2",
  //   element: (<GuestGuard><SignUpItemDetails2 /></GuestGuard>),
  // },
  {
    path: "/my-event",
    // eslint-disable-next-line
    element: (<MyEvent />),
  },
  {
    path: "/cart",
    element: (<AuthGuard><CartPage /></AuthGuard>),
  },
  {
    path: "/notification",
    element: (<AuthGuard><Notification /></AuthGuard>),
  },
  {
    path: "/notification-list",
    element: (<AuthGuard><NotificationHistory /></AuthGuard>),
  },
  {
    path: "/paymentMethod",
    element: (<AuthGuard><PaymentMethod /></AuthGuard>),
  },
  {
    path: "/payment",
    element: (<AuthGuard><PaymentPage /></AuthGuard>),
  },
  // {
  //   path: "/filter",
  //   element: (<FilterPage />),
  // },
  {
    path: "/vendor/dashboard",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService/addProduct/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService/getProduct/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService/secondProduct",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/orderManagement",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/orderManagement/viewOrder/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),

  },
  {
    path: "/vendor/paymentManagement/viewOrder/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),

  },
  {
    path: "/vendor/productAndService/addOns/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),

  },
  {
    path: "/vendor/paymentManagement",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/reportManagement",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/invoiceManagement",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService/allSection",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService/addOnsDetail/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/productAndService/addOnsSecond/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/paymentDetails/:id",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/myProfile",
    element: (<VendorGuard><Profile /></VendorGuard>),
  },

  {
    path: "vendor/productAndService/addOns/:id/preview",
    element: (<VendorGuard><VendorLanding /></VendorGuard>),
  },
  {
    path: "/vendor/contact-details",
    element: (<VendorGuard><ContactDetails /></VendorGuard>),
  },
  {
    path: "/vendor/bank-details",
    element: (<VendorGuard><BankDetails /></VendorGuard>),
  },
  {
    path: "/vendor/coverage-details",
    element: (<VendorGuard><CoverageDetails /></VendorGuard>),
  },
  {
    path: "/vendor/customer-care",
    element: (<VendorGuard><VendorCustomerCare /></VendorGuard>),
  },
  {
    path: "/vendor/about",
    element: (<AuthGuard><AboutUs /></AuthGuard>),
  },
  {
    path: "/automate_event",
    element: (<AuthGuard><Automate_Event /></AuthGuard>),
  },
  {
    path: "/event-list",
    element: (<Event_list />),
  },
  {
    path: "/event-details/:id",
    element: (<Event_detail />),
  },
  {
    path: "/hot-deals",
    element: (<Hot_deals />),
  },
  {
    path: "/search-by-category",
    element: (<SearchByCategory />),
  },
  {
    path: "/vendor-detail/:id",
    element: (<VendorDetail />),
  },
  {
    path: "/category-detail/:id",
    element: (<CategoryDetail />),
  },
  {
    path: "/user/profile",
    element: (<AuthGuard><EditUserProfile /></AuthGuard>),
  },
  {
    path: "/event-detail/:id",
    element: (<AuthGuard><EventDetail /></AuthGuard>),
  },
  // {
  //   path: "/event-detail-old/:id",
  //   element: (<AuthGuard><EventDetail_old /></AuthGuard>),
  // },
  {
    path: "/my-attendees/:id",
    element: (<AuthGuard><MyAttendees /></AuthGuard>),
  },
  {
    path: "/invitation/:id",
    element: (<Invitation />),
  },
  {
    path: "/address",
    element: (<AuthGuard><UserAddress /></AuthGuard>),
  },
  {
    path: "/customer-care",
    element: (<AuthGuard><CustomerCare /></AuthGuard>),
  },
  {
    path: "/about",
    element: (<AuthGuard><AboutUs /></AuthGuard>),
  },
];

export default routes;