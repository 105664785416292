import React, { useState } from "react";
import "./LoginSignupDefault.scss";
import {
  Box,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import LeftLogo from "./Component/LeftLogo";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { vendorCreateAccountValidation } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyBasicDetails,
  addVendorBasicDetails,
} from "../../redux/actions/companyActions";
import { toast } from "react-toastify";
import {
  companyBasicDetailsSignUpProcess,
  vendorBasicDetailsSignUpProcess,
} from "../../api/userApi";
import { BorderBottom, Visibility, VisibilityOff } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import LoaderComponent from "../../vendor/Content/Loader/loader";

//Styling with useStyle

const useStyles = makeStyles((theme) => ({
  margin80: {
    marginTop: "80px !Important",
  },
  margin50: {
    marginTop: "50px !Important",
  },
  margin20: {
    marginTop: "20px !Important",
  },
  timePickerRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1), // Adjust margin bottom as needed
  },
  labelMargin: {
    marginRight: theme.spacing(1), // Adjust margin right as needed
  },
  margin10: {
    marginTop: "10px !Important",
  },
  errors: {
    color: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    border: "2px solid #008E7A !Important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SignUpVendorDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const vendorData = useSelector((state) => state.companyReducer.vendorDetail);
  const [vendorDetails, setVendorDetails] = useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable for submission status
  const [isLoading, setIsLoading] = useState(false); // State variable for loader
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setVendorDetails((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setVendorDetails((preValue) => {
      return {
        ...preValue,
        profileImage: file,
      };
    });
    setSelectedFileName(file ? file.name : "");
  };


  const handlePhoneChange = (value) => {
    setVendorDetails((prevValue) => ({
      ...prevValue,
      mobile: value,
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, dirtyFields },
  } = useForm({ resolver: yupResolver(vendorCreateAccountValidation) });

  const formSubmitNext = async () => {
    setIsSubmitting(true);
    setIsLoading(true); // Activate loader
    const formData = new FormData();
    formData.append("emailId", vendorDetails.emailId);
    formData.append("firstname", vendorDetails.firstname);
    formData.append("lastname", vendorDetails.lastname);
    formData.append("mobile", vendorDetails.mobile);
    formData.append("password", vendorDetails.password);
    // formData.append("profileImage", vendorDetails.profileImage);
    formData.append("role", "vendor");
    if (!vendorDetails.profileImage) {
      setError("profileImage", {
        type: "manual",
        message: "Profile image is required",
      });
      setIsSubmitting(false);
      setIsLoading(false);
      return;
    }

    formData.append("profileImage", vendorDetails.profileImage);

    dispatch(addVendorBasicDetails(formData));
    await callAPI(formData);
  };

  const callAPI = async (data) => {
    setIsSubmitting(true); // Set to true when form submission starts
    try {
      const user = await vendorBasicDetailsSignUpProcess(data);
      if (user) {
        localStorage.setItem("EM_Vendor", JSON.stringify(user));
        dispatch({ type: "EMPTY_VENDOR_BASIC_DETAILS" });
        dispatch(addVendorBasicDetails(user));

        if (user.isAlreadyRegistered === true) {
          toast.error(user?.message);
          console.log(user.data, "User");
          // setTimeout(() => {
          if (user.data.companyDetail === false) {
            navigate("/signup-company-details");
          } else if (user.data.contactDetail === false) {
            navigate("/signup-contact-details");
          } else if (user.data.bankDetail === false) {
            navigate("/signup-bank-details");
          } else if (user.data.itemDetail === false) {
            navigate("/signup-item-details");
          } else {
            navigate("/loginv2");
          }
          // }, 3000);
        }
        if (user.isAlreadyRegistered === false) {
          toast.success(user?.message);
          // setTimeout(() => {
          navigate("/signup-company-details");
          // }, 3000);
        }
        if (user.isNewUser === true) {
          toast.success(user?.message);
          // setTimeout(() => {
          navigate("/signup-company-details");
          // }, 3000);
        }
      }
    } catch (err) {
      //console.log(err.data);
      toast.error(err?.message[0]);
      setIsLoading(false); // Activate loader
    } finally {
      setIsSubmitting(false); // Set back to false when API call is complete or fails
      setIsLoading(false); // Activate loader
    }
  };

  return (
    <Box className="login-signup-default">
      <Box className="d-flex flex-wrap">
        <LeftLogo />
        <Box className="col-xxl-7 col-sm-8 col-12 right-box-outer">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <Box className="container h-100 d-flex align-items-lg-center justify-content-center py-4">
              <Box className="right">
                <Box className="tagline">"Bring Your Event to Life"</Box>
                <h1>Vendor Details</h1>
                <Box
                  component="form"
                  onSubmit={handleSubmit(formSubmitNext)}
                  className="form-box height-450"
                >
                  <TextField
                    className={`customInputFilledDesign `}
                    {...register("firstname")}
                    id="filled-basic"
                    fullWidth
                    label="First Name"
                    autoComplete="off"
                    variant="filled"
                    name="firstname"
                    onChange={handleChange}
                  />
                  {errors?.firstname && (
                    <p className={`${classes.errors}`}>
                      {errors?.firstname?.message}
                    </p>
                  )}

                  <TextField
                    className={`customInputFilledDesign `}
                    {...register("lastname")}
                    id="outlined-basic"
                    label="Last Name"
                    variant="filled"
                    autoComplete="off"
                    name="lastname"
                    onChange={handleChange}
                  />
                  {errors?.lastname && (
                    <p className={`${classes.errors}`}>
                      {errors?.lastname?.message}
                    </p>
                  )}

                  <TextField
                    className={`customInputFilledDesign`}
                    {...register("emailId")}
                    id="outlined-basic"
                    label="Email Id"
                    variant="filled"
                    autoComplete="off"
                    name="emailId"
                    onChange={handleChange}
                  />
                  {errors?.emailId && (
                    <p className={`${classes.errors}`}>
                      {errors?.emailId?.message}
                    </p>
                  )}

                  {/* password */}
                  <Box className={` rounded-3 `}>
                    <TextField
                      className={` customInputFilledDesign`}
                      {...register("password")}
                      id="outlined-adornment-password"
                      name="password"
                      autoComplete="off"
                      label="Password"
                      variant="filled"
                      fullWidth
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "white",
                              height: "100%",
                            }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  {errors?.password && (
                    <p className={`${classes.errors}`}>
                      {errors?.password?.message}
                    </p>
                  )}
                  <Box className={`rounded-3 inputNumber mb-4 `}>
                    <Controller
                      name="mobile"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          // country={"in"}
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value); // Update the form state
                            handlePhoneChange(value); // Update the local state
                          }}
                          inputProps={{
                            name: "mobile",
                            required: false,
                            autoFocus: false,
                            placeholder: "Mobile Number",
                            autoComplete: "off", // Disable autocomplete
                          }}
                          inputStyle={{
                            width: "100%",
                          }}
                          sx={{
                            BorderBottom: "1px solid red !Important",
                            "& .special-label": {
                              display: "none",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  {errors?.mobile && (
                    <p className={classes.errors}>{errors?.mobile?.message}</p>
                  )}

                  <TextField
                    id="filled-basic"
                    label="Profile Image"
                    name="profileImage"
                    variant="filled"
                    value={selectedFileName}
                    className={`customInputFilledDesign `}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <input
                            id="tradeLicense"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleChangeImage}
                            accept="image/*"
                          />
                          <label htmlFor="tradeLicense">
                            <IconButton
                              component="span"
                              sx={{
                                width: "38px",
                              }}
                            >
                              <svg
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 7.25H3.5C2.90326 7.25 2.33097 7.48705 1.90901 7.90901C1.48705 8.33097 1.25 8.90326 1.25 9.5V18.5C1.25 19.0967 1.48705 19.669 1.90901 20.091C2.33097 20.5129 2.90326 20.75 3.5 20.75H12.5C13.0967 20.75 13.669 20.5129 14.091 20.091C14.5129 19.669 14.75 19.0967 14.75 18.5V9.5C14.75 8.90326 14.5129 8.33097 14.091 7.90901C13.669 7.48705 13.0967 7.25 12.5 7.25H11M11 4.25L8 1.25M8 1.25L5 4.25M8 1.25V14"
                                  stroke="#757575"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </label>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.profileImage && (
                    <p className={classes.errors}>{errors?.profileImage?.message}</p>
                  )}
                  <FormGroup className="submit-box mt-4">
                    <Box className="d-flex flex-wrap gap-2 gap-xxl-4">
                      <button
                        className="default-btn white-bg"
                        onClick={() => {
                          navigate("/loginv2");
                        }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </button>
                      <button
                        className="default-btn green-bg"
                        type="submit"
                        disabled={
                          isSubmitting || Object.keys(dirtyFields).length === 0
                        }
                      >
                        Next
                      </button>
                    </Box>
                  </FormGroup>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpVendorDetails;
