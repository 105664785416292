import './loader.scss'

const LoaderComponent = () => {
  return (
    <div className="banter-loader">
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
    <div className="banter-loader__box"></div>
</div>
  )
}

export default LoaderComponent