import * as yup from "yup";

export const validationSchema = yup
  .object({
    name: yup.string().required().max(50, "Max 50 characters allowed"),
    email: yup.string().email().required(),
    password: yup.string().required().min(8, "Your Password should have 8 "),
    rePassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

export const loginValidationSchema = yup.object({
  emailId: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const signupValidationSchema = yup
  .object({
    firstname: yup
      .string()
      .required()
      .max(50, "Max 50 characters allowed")
      .matches(/^[a-zA-Z]+$/, "Only alphabet character allowed"),
    lastname: yup
      .string()
      .required()
      .max(50, "Max 50 characters allowed")
      .matches(/^[a-zA-Z]+$/, "Only alphabet character allowed"),
    email: yup.string().email().required("Email is required"),
    mobile: yup
      .string()
      .required("Mobile number is required")
      .matches(
        /^(\+\d{1,3})?\d{8,14}$/,
        "Invalid mobile number. Must be between 9 and 15 digits."
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Your Password should have 8 "),
    repassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    terms: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  })
  .required();

export const forgetValidationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const vendorCreateAccountValidation = yup
  .object({
    firstname: yup
      .string()
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("First Name is Required")
      .max(50, "Max 50 characters allowed"),
    lastname: yup
      .string()
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("Last Name is Required"),
    emailId: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    mobile: yup
      .string()
      .required("Mobile number is required")
      .matches(
        /^(\+\d{1,3})?\d{8,14}$/,
        "Invalid mobile number. Must be between 9 and 15 digits."
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*[!@#\$%\^&\*])/,
        "Password must contain at least one special character"
      ),
  })
  .required();

export const companyCreateAccountValidation = yup
  .object({
    company_name: yup
      .string()
      .required("Company Name is Required")
      .max(50, "Max 50 characters allowed"),
    bussiness_type: yup.string().required("Bussiness Type is Required"),
    bussiness_location: yup.string().required("Bussiness Location is Required"),
    number_of_branches: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      ) // Convert empty string to undefined
      .nullable() // Allow null values
      .required("Number of Branches is required")
      .positive("Number of Branches must be a positive number"),
    license_expiry_date: yup
      .date()
      .typeError("Invalid Date")
      .required("License Expiry Date is required")
      .min(new Date(), "License Expiry Date must be in the future"),
    license_number: yup.string().required("License Number is required"),
    single_owner: yup.string().required("Required"),
    working_hours: yup.array().of(
      yup.object().shape({
        day: yup.string().required("Day is required"),
        openingTime: yup.string().required("Opening Time is required"),
        closingTime: yup.string().required("Closing Time is required"),
      })
    ),
  })
  .required();

  const urlPattern = /^(?:(?:https?):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-./?%&=#]*)?$/i;

  const urlValidation = yup.string().matches(urlPattern, 'Invalid URL');
      

export const contactDetailsValidation = yup
  .object({
    company_email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
      website: urlValidation.required('URL is required'),
      social_media_account: urlValidation.required("Social Media is required"),
      contact_name: yup
      .string()
      .required("Contact Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
    contact_number: yup
      .string()
      .required("Contact number is required")
      .matches(
        /^(\+\d{1,3})?\d{8,14}$/,
        "Invalid contact number. Must be between 9 and 15 digits."
      ),
    contact_position: yup
      .string()
      .required("Contact Position is required")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
    // contact_id: yup.string().required("Contact ID is required"),
  })
  .required();

export const bankDetailsValidation = yup
  .object({
    bank_account: yup
      .string()
      .matches(/^[0-9]*$/, "Bank account can only contain numbers")
      .required("Bank account is required"),
    beneficiary_name: yup
      .string()
      .required("Beneficiary Name is required")
      .max(50, "Max 50 characters allowed"),
    bank_name: yup.string().required("Bank Name is required"),
    // additional_bank_info: yup.string('Additional Bank Info is required').required().max(100, "Max 100 characters allowed"),
    iban_number: yup
      .string()
      .required("IBAN number is required")
      .max(100, "Max 100 characters allowed"),
    iban_certificate: yup.string().required("IBAN Certificate is required"),
  })
  .required();

export const itemDetailsValidation = yup
  .object({
    general_detail: yup
      .string()
      .required("General Details are required")
      .max(50, "Max 50 characters allowed"),
  })
  .required();

// =================================

// section_name_id:65bc95f7be30a43b1d27b8e6
// service_item_name:Bre
// item_description:Rum juice good for health
// event_type:654c5910a7a58b0f8ac2f66e
// service_item_price:90
// security_amount:0
// attendee_no[min]:10
// attendee_no[max]:2000
// service_item_location:Florida
// cancellation_policy:this is the policy for cancellation_policy
// deduction[number_of_days]:5
// deduction[deduction_percentage]:2
// down_payment_required:10
// setup_time:10
// maximum_hours:40
// area[length]:350
// area[width]:405
// electricity_outlet:true
// ventilation:false

const wordCount = (value) => {
  if (!value) return true; // If the value is empty or undefined, it will be handled by the required rule.
  return value.split(/\s+/).length <= 200; // Check if the number of words is less than or equal to 200
};

export const productValidation = yup.object({
  service_item_name: yup
    .string()
    .required("Item name is required")
    .max(50, "Max 50 characters allowed"),
  event_type: yup.string().required("Event type is required"),
  item_description: yup
    .string()
    .required("Item Description is required")
    .test("maxWords", "Item Description must be 200 words or less", wordCount),
  service_item_price: yup.string().required("Item Price is required"),

  discount: yup.object().shape({
    discount_percentage: yup
      .number()
      .typeError("Discount must be a number")
      .required("Discount is required")
      .min(0, "Discount must be greater than or equal to 0")
      .max(100, "Discount must be less than or equal to 100")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    no_of_days: yup
      .number()
      .typeError("Number of days must be a number")
      .min(0, "Number of days must be greater than or equal to 0")
      .max(999, "Number of days must be less than or equal to 999")
      .test(
        "maxDigits",
        "Number of days must have at most 3 digits",
        (value) => {
          return (
            value === undefined ||
            value === null ||
            value.toString().length <= 3
          );
        }
      )
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
  }),

  minimumBookingTime: yup
    .number()
    .typeError("Minimum booking time must be a number")
    .min(1, "Minimum booking time must be at least 1 hour")
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .nullable(),

  maximumBookingTime: yup
    .number()
    .typeError("Maximum booking time must be a number")
    .moreThan(
      yup.ref("minimumBookingTime"),
      "Maximum booking time must be greater than minimum booking time"
    )
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .nullable(),
  security_amount: yup
    .number()
    .typeError("Security Amount must be a number")
    .required("Security Amount is required")
    .positive("Number of days must be greater than 0")
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .test(
      "is-less-than-price",
      "Security Amount must be less than or equal to Item Price",
      function (value) {
        const itemPrice = this.parent.service_item_price; // Accessing the value of Item Price
        return parseFloat(value) <= parseFloat(itemPrice); // Comparing Security Amount with Item Price
      }
    ),
  service_item_location: yup.string().required("Item location is required"),
  attendee_no: yup.object().shape({
    min: yup
      .number()
      .typeError("Min number of attendees must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable()
      .min(1, "Min number of attendees must be greater than or equal to 1"),
    max: yup
      .number()
      .typeError("Max number of attendees must be a number")
      .required("Max number of attendees is required")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable()
      .min(1, "Max number of attendees must be greater than or equal to 1")
      .min(
        yup.ref("min"),
        "Max attendees must be greater than or equal to min attendees"
      ),
  }),
  area: yup.object().shape({
    length: yup
      .number()
      .typeError("Length must be a number")
      .min(1, "Area length must be greater than or equal to 1")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable(),
    width: yup
      .number()
      .typeError("Width must be a number")
      .min(1, "Area width must be greater than or equal to 1")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable(),
  }),
  // setup_time: yup
  //   .number()
  //   .typeError("Setup time must be a number")
  //   .min(1, 'Setup time must be greater than or equal to 1')
  //   .transform((value, originalValue) => {
  //     return originalValue === '' ? null : value;
  //   })
  //   .nullable(),
  // maximum_hours: yup
  //   .number()
  //   .typeError("Maximum hours must be a number")
  //   .min(1, 'Maximum hours must be greater than or equal to 1')
  //   .transform((value, originalValue) => {
  //     return originalValue === '' ? null : value;
  //   })
  //   .nullable(),

  down_payment_required: yup
    .number()
    .typeError("Down payment must be a number")
    .min(0, "Down payment must be greater than or equal to 0")
    .max(100, "Down payment must be less than or equal to 100")
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value; // Convert empty string to null
    })
    .nullable(),
  deduction: yup.object().shape({
    deduction_percentage: yup
      .number()
      .min(0, "Discount must be greater than or equal to 0")
      .max(100, "Discount must be less than or equal to 100")
      .typeError("Deduction percentage must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable(),
    number_of_days: yup
      .number()
      .min(0, "Number of days must be greater than or equal to 0")
      .max(999, "Number of days must be less than or equal to 999")
      .test(
        "maxDigits",
        "Number of days must have at most 3 digits",
        (value) => {
          return (
            value === undefined ||
            value === null ||
            value.toString().length <= 3
          );
        }
      )
      .typeError("Number of days must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable()
      .min(0, "Number of days must be greater than 0"),
  }),
  dailyAvailableQuantity: yup
    .number()
    .required("Daily Available Quantity is required")
    .typeError("Daily Available Quantity must be a number")
    .positive("Daily Available Quantity must be a positive number")
    .integer("Daily Available Quantity must be an integer"),
});
// ===================================
