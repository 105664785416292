import { createContext, useReducer, useContext } from "react";
import { companyBasicDetailsSignUpProcess, createPassowrd, loginApi, otpGen, otpVerify, signupApi, socialLogin, verifyToken } from "../api/userApi";
import { toast } from "react-toastify";
import { addVendorBasicDetails } from "../redux/actions/companyActions";

const handlers = {
    USER_DATA: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            data
        };
    },
    OTP_EMAIL: (state, action) => {
        const data = action.payload;
        return { ...state, data }
    },
    LANGUAGE: (state, action) => {
        const { language } = action.payload;
        return { ...state, language }
    }
}

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const initialState = {
    language: "english"
};

export const UserContext = createContext({
    ...initialState,
    getUser: () => Promise.resolve(),
});


export const UserProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <UserContext.Provider
            value={{
                ...state,
                dispatch,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

const useUserContext = () => useContext(UserContext);

export default useUserContext;

export const userLoginData = async (data, dispatch, navigate) => {
    console.log(data, "data");
    try {
        dispatch({
            type: "USER_DATA",
            payload: {
                isLoading: true,
            },
        });
        const user = await loginApi(data);
        console.log(user, "USer", user.data.status, user.data.role);

        if (user?.data?.role === "vendor" && user?.data?.status === false) {
            toast.error("Once Admin Will Verified Account, you can login in your account.");
            return; // Exit early since the account needs admin verification
        }

        if (user.data.status === true) {
            localStorage.setItem("EM_User", JSON.stringify(user));
            localStorage.setItem("EM_TOKEN", user?.token || "");
            dispatch({
                type: "USER_DATA",
                payload: {
                    user,
                    isLoading: false,
                },
            });
            // Check if the user role is "vendor"
            console.log(user?.data, "Vendor");

            if (user.data.role === "vendor") {

                navigate('/vendor/dashboard');

            } else {
                navigate("/");
            }
        }
    } catch (err) {
        toast.error(err?.message);
        if (err?.data?.role === "vendor") {
            localStorage.setItem("EM_Vendor", JSON.stringify(err));
            dispatch(addVendorBasicDetails(err))
            if (err?.data?.companyDetail === false) {
                navigate('/signup-company-details')
            } else if (err?.data?.contactDetail === false) {
                navigate('/signup-contact-details')
            } else if (err?.data?.bankDetail === false) {
                navigate('/signup-bank-details')
            } else if (err?.data?.itemDetail === false) {
                navigate('/signup-item-details')
            } else {
                navigate('/');
            }
        }
        dispatch({
            type: "USER_DATA",
            payload: {
                user: null,
                isLoading: false,
            },
        });
    }
};


export const userSignupData = async (data, dispatch, navigate) => {

    try {
        dispatch({
            type: "USER_DATA",
            payload: {
                isLoading: true,
            },
        });
        const user = await signupApi(data);
        if (user) {
            // localStorage.setItem("EM_User", JSON.stringify(user));
            dispatch({
                type: "USER_DATA",
                payload: {
                    user,
                    isLoading: false,
                },
            });
            navigate("/loginv2")
        }
        // else{
        //     toast.error(user?.msg);
        //     dispatch({
        //         type: "USER_DATA",
        //         payload: {
        //             isLoading: false,
        //         },
        //     });
        // }
    } catch (err) {
        toast.error(err?.message);
        dispatch({
            type: "USER_DATA",
            payload: {
                user: null,
                isLoading: false,
            },
        });
    }
};

export const userVerifyToken = async (data, dispatch, navigate) => {

    try {
        const user = await verifyToken(data);
        if (user.status === 400) {
            dispatch({
                type: "USER_DATA",
                payload: {
                    isLoading: false,
                },
            });

        }

    } catch (err) {
        navigate("/");
        localStorage.removeItem("EM_User");
        toast.error("Season Expire");
        dispatch({
            type: "USER_DATA",
            payload: {
                isLoading: false,
            },
        });
    }
};

export const userOtpGen = async (data, dispatch, navigate) => {

    try {
        dispatch({
            type: "OTP_EMAIL",
            payload: {
                isLoading: true,
            },
        });
        const otpEmail = await otpGen(data);
        if (otpEmail.status === 200) {
            dispatch({
                type: "OTP_EMAIL",
                payload: {
                    isLoading: false,
                    otpEmail
                },
            });
            navigate("/otp")
        }
        // else{
        //     toast.error(otpEmail?.msg);
        //     dispatch({
        //         type: "OTP_EMAIL",
        //         payload: {
        //             isLoading: false,
        //         },
        //     });
        // }
    } catch (err) {
        toast.error(err?.msg);
        dispatch({
            type: "OTP_EMAIL",
            payload: {
                otpEmail: null,
                isLoading: false,
            },
        });
    }
};

export const userOtpVerify = async (data, dispatch, navigate, token) => {

    try {
        dispatch({
            type: "OTP_EMAIL",
            payload: {
                isLoading: true,
            },
        });
        const otpEmail = await otpVerify(data, token);
        if (otpEmail.status === 200) {
            dispatch({
                type: "OTP_EMAIL",
                payload: {
                    isLoading: false,
                    otpEmail
                },
            });
            navigate("/create_new_password");
        }
    } catch (err) {
        toast.error(err?.msg);
        dispatch({
            type: "OTP_EMAIL",
            payload: {
                otpEmail: null,
                isLoading: false
            },
        });
    }
};

export const userCreatePassword = async (data, handleClickOpen) => {

    try {
        let token = data?.token;
        let data2 = data;
        data = {}
        data = { emailId: data2?.emailId, password: data2?.password }
        const user = await createPassowrd(data, token);
        if (user.status === 200) {
            handleClickOpen();
        }
    } catch (err) {
        toast.error(err?.msg);
    }
};

export const userSocialLogin = async (data, dispatch, navigate) => {

    try {
        dispatch({
            type: "USER_DATA",
            payload: {
                isLoading: true,
            },
        });
        const user = await socialLogin(data);
        if (user.status === 200) {
            localStorage.setItem("EM_User", JSON.stringify(user));
            dispatch({
                type: "USER_DATA",
                payload: {
                    user,
                    isLoading: false,
                },
            });
            navigate("/");
        }
    } catch (err) {
        if (err?.message !== "Network Error")
            toast.error(err?.response?.data?.msg);
        toast.error(err?.message);
        dispatch({
            type: "USER_DATA",
            payload: {
                user: null,
                isLoading: false,
            },
        });
    }
};

export const userLanguage = async (data, dispatch) => {
    dispatch({
        type: "LANGUAGE",
        payload: {
            language: data
        }
    })
}

//Company SignUp Process Starts//
export const companySignupData = async (data, dispatch, navigate) => {

    try {
        dispatch({
            type: "USER_DATA",
            payload: {
                isLoading: true,
            },
        });
        const user = await companyBasicDetailsSignUpProcess(data);
        if (user.status === 200) {
            localStorage.setItem("EM_User", JSON.stringify(user));
            dispatch({
                type: "USER_DATA",
                payload: {
                    user,
                    isLoading: false,
                },
            });
            navigate('/signup-bank-details')
        }
        // else{
        //     toast.error(user?.msg);
        //     dispatch({
        //         type: "USER_DATA",
        //         payload: {
        //             isLoading: false,
        //         },
        //     });
        // }
    } catch (err) {
        toast.error(err?.msg);
        dispatch({
            type: "USER_DATA",
            payload: {
                user: null,
                isLoading: false,
            },
        });
    }
};