import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CommonProvider } from "./contexts/test";
import { UserProvider } from "./contexts/userContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {store} from "./redux/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { createTheme } from "@mui/material";
const theme = createTheme({
  // Define your custom theme here
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode> close because due to this useEffect caling it self twice
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ToastContainer theme="colored" style={{ zIndex: "99999" }} />
      <CommonProvider>
        <UserProvider>
            <Provider store={store}>
              <App />
            </Provider>
        </UserProvider>
      </CommonProvider>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
reportWebVitals();
