import axios from "axios";
import { config } from "../config";

export const loginApi = (payload) => new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_WEB_URL}/user/login`, payload).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const signupApi = (payload) => new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_WEB_URL}/user/signup`, payload).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
})

export const verifyToken = (token) => new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_WEB_URL}/user/verifyToken`, { headers: { "Authorization": `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } }).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const otpGen = (payload) => new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_WEB_URL}/user/otpGen`, payload).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const otpVerify = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/otpVerify`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const createPassowrd = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/createPassword`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const socialLogin = (payload) => new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_WEB_URL}/user/googleLogin`, payload).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error);
    })
});

//Company SignUp process API's

export const vendorBasicDetailsSignUpProcess = (payload) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/vendor/signup`,
        payload,
        {
            header: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        }
    ).then((res) => {
        const data = res?.data;
        return resolve(data)
    }).catch((error) => {
        return reject(error?.response?.data);
    })
});

export const companyBasicDetailsSignUpProcess = (payload, token) => new Promise((resolve, reject) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    axios.post(
        `${process.env.REACT_APP_WEB_URL}/vendor/signup/company-detail`,
        payload,
        config
    ).then((res) => {
        const data = res?.data;
        return resolve(data)
    }).catch((error) => {
        return reject(error?.response?.data);
    })
});

export const companyItemDetailsSignUpProcess = (payload) => new Promise((resolve, reject) => {
    const localStorageData = localStorage.getItem("EM_Vendor");
    const parsedData = JSON.parse(localStorageData);
    const token = parsedData?.token

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    axios.post(
        `${process.env.REACT_APP_WEB_URL}/vendor/signup/item-detail`,
        payload,
        config
    ).then((res) => {
        const data = res?.data;
        return resolve(data)
    }).catch((error) => {
        return reject(error?.response?.data);
    })
});

export const fetchEventTypeList = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/vendor/product/event-type`,
        {
            header: {
                // "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});


export const fetchCategoryList = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/vendor/categories-with-subcategories`,
        {
            header: {
                // "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const getCategoryList = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/category`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});


export const fetchSubCategoryByParentIdList = (payload, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/vendor/sub-category/${payload._id}`,
        {
            header: {
                // "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchUserPreferencesList = (token, params) => new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_WEB_URL}/user/homepage/preferences${params || ""}`;
    axios.get(
        url,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postUserPreferences = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/preferences`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});


export const fetchEventList = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/search`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchGlobalSearchList = (params, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/global-search?${params}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchVendorByCatList = (params, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/vendorsByCat?${params}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchHotDealsList = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/hot-deals`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postChangeEmailId = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/change-email-password`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postChangeEmailIdVerifyOtp = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/change-email-verify-otp`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchUpcommingEventList = (query, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/all${query}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchCompletedEventList = (query, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/all${query}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchCancelledEventList = (query, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/all${query}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchEventOrderDetails = (id, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postChangePassword = (payload, token) => new Promise((resolve, reject) => {
    axios.put(
        `${process.env.REACT_APP_WEB_URL}/user/profile/create-password`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postUpdateProfile = (payload, token) => new Promise((resolve, reject) => {
    axios.patch(
        `${process.env.REACT_APP_WEB_URL}/user/profile/update-profile`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postUpdateVendorProfile = (payload, token) => new Promise((resolve, reject) => {
    axios.patch(
        `${process.env.REACT_APP_WEB_URL}/vendor/profile/update-profile`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});


export const deleteAccount = (payload, token) => new Promise((resolve, reject) => {
    axios.delete(
        `${process.env.REACT_APP_WEB_URL}/user/profile/me`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const fetchNotificationList = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/notification`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const userEventCancelledApi = (id, payload, token) => new Promise((resolve, reject) => {
    axios.put(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/cancel/${id}`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const userOrderCancelledApi = (id, payload, token) => new Promise((resolve, reject) => {
    axios.put(
        `${process.env.REACT_APP_WEB_URL}/vendor/order-management/cancel/${id}`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const userEventNameUpdateApi = (id, payload, token) => new Promise((resolve, reject) => {
    axios.patch(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/${id}`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const userEventFeedBackApi = (id, payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/my-events/feedback/${id}`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const postCustomerSupport = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/support/create-ticket`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const getUserFaq = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/support/FAQ`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const getUserContactUs = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/profile/contact-us`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const getUserVision = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/profile/vision-mission`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const getUserPolicy = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/vendor/profile/policy`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const getUserTermsUses = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/profile/terms-uses`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const postUserApplicationFeedback = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/application-feedback`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const postUserAddAddress = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/profile/save-address`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const getUsersAddress = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/profile/all-saved-address`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const deleteUserAddress = (id, token) => new Promise((resolve, reject) => {
    axios.delete(
        `${process.env.REACT_APP_WEB_URL}/user/profile/saved-address/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    });
});

export const fetchAllRecentOrders = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/my-events`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchVendorDetail = (id, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/${id}/details`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchCategoryDetail = (id, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/package-item/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchTopRatedVendor = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/vendor/fetch-top-vendors`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchLatestVendor = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/fetch-latest-vendors/5`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postUserCard = (payload, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/profile/save-card`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const patchUserCard = (payload, id, token) => new Promise((resolve, reject) => {
    axios.patch(
        `${process.env.REACT_APP_WEB_URL}/user/profile/saved-card/${id}`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchUserCardList = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/profile/all-saved-cards`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchUserPaymentHistory = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/profile/user-payments`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const deleteUserCard = (id, token) => new Promise((resolve, reject) => {
    axios.delete(
        `${process.env.REACT_APP_WEB_URL}/user/profile/saved-card/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchAllAttendees = (id, params, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/invitation/${id}/fetchAttendees${params}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchInviteDetail = (id, token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/invitation/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const postUserInvite = (payload, id, token) => new Promise((resolve, reject) => {
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/invitation/${id}`,
        payload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchCartsDetails = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/view-cart`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const fetchCartsCount = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/count-cart`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
}); //user/homepage/update-quantity/660e56919047ff2420838a06?quantity=5

export const userCartItemQtyUpdate = (id, qty, token) => new Promise((resolve, reject) => {
    axios.patch(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/update-quantity/${id}?quantity=${qty}`,
        {},
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});


export const userCartItemRemove = (id, token) => new Promise((resolve, reject) => {
    axios.delete(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/search/remove-from-cart/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const userAddToCart = (payload, id, token) => new Promise((resolve, reject) => {
    console.log(payload,"Payload");
    axios.post(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/search/add-to-cart/${id}?quantity=${payload?.qty}`,
        payload.selectedCourse,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});

export const userCheckoutForSinglePayment = (token) => new Promise((resolve, reject) => {
    axios.get(
        `${process.env.REACT_APP_WEB_URL}/user/homepage/payment`,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    ).then((res) => {
        const data = res?.data;
        resolve(data)
    }).catch((error) => {
        reject(error?.response?.data);
    })
});