import React, { useEffect, useRef, useState } from "react";
import "./LoginSignupDefault.scss";
// import Logo from '../../assert/image/logo.png'
import { Box, Button, FormGroup, InputLabel, TextField } from "@mui/material";
import LeftLogo from "./Component/LeftLogo";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bankDetailsValidation, contactDetailsValidation } from "./validation";
import { makeStyles } from "@material-ui/core/styles";
import {
  addBankDetails,
  addContactDetails,
} from "../../redux/actions/bankActions";
import PhoneInput from "react-phone-input-2";
import LoaderComponent from "../../vendor/Content/Loader/loader";

const useStyles = makeStyles((theme) => ({
  errors: {
    color: "red",
  },
  margin50: {
    marginTop: "50px !Important",
  },
  uploadButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const SignUpContactDetails = () => {
  const navigate = useNavigate();
  const vendorData = useSelector((state) => state.companyReducer.vendorDetail);
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState(""); // State for file name
  const [selectedFile, setSelectedFile] = useState(null)
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // State variable for loader

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactDetailsValidation) });
  const formData1 = new FormData();

  const formData = new FormData();
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handlePhoneChange = (value) => {
    setValue("contact_number", value);
  };
  const handleFileChange = (files) => {
    console.log("files:", files);


    if (files?.length > 0) {
      const file = files[0]; // Access the first file object in the array
      setSelectedFileName(file.name);
      setSelectedFile(file)

    }
  };
  const formSubmitNext = async (data) => {
    setIsLoading(true);
    try {
      Object.entries(data).forEach(([key, value]) => {
        if (key !== "contact_id") {
          formData.append(key, value);
        }
      });

      if (selectedFile) {
        formData.append("contact_id", selectedFile);
      }else {
        setError("contact_id", {
          type: "manual",
          message: "Please select a file",
        });
        throw new Error("Please select a file");
      }
      const responseData = await dispatch(
        addContactDetails(formData, vendorData.token)
      );
      toast.success("Contact Details Saved Successfully.");
      navigate("/signup-bank-details");
    } catch (error) {
      if (error?.response?.data.contactDetail === true) {
        setTimeout(() => {
          navigate("/signup-bank-details");
        }, 6000);
      }
      toast.error(error?.response?.data?.message);
      console.error("Error submitting form:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="login-signup-default">
      <Box className="d-flex flex-wrap">
        <LeftLogo />
        <Box className="col-xxl-7 col-sm-8 col-12 right-box-outer">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <Box className="container h-100 d-flex align-items-lg-center justify-content-center py-4">
              <Box className="right">
                <Box className="tagline">"Bring Your Event to Life"</Box>
                <h1>Contact Details</h1>
                <Box
                  component="form"
                  className="form-box height-450 vendor-form"
                  onSubmit={handleSubmit(formSubmitNext)}
                >
                  <FormGroup
                    className={`customInputFilledDesign  input-box inputNumber`}
                  >
                    <input
                      type="text"
                      {...register("company_email")}
                      name="company_email"
                      id="name"
                      placeholder=" "
                    />
                    <label htmlFor="name">Company Email</label>
                  </FormGroup>
                  {errors?.company_email && (
                    <p className={`${classes.errors}`}>
                      {errors?.company_email?.message}
                    </p>
                  )}
                  <FormGroup
                    className={`customInputFilledDesign inputNumber input-box`}
                  >
                    <input
                      type="text"
                      {...register("website")}
                      name="website"
                      id="type"
                      placeholder=" "
                    />
                    <label htmlFor="type" for="type">Company Website</label>
                  </FormGroup>
                  {errors?.website && (
                    <p className={`${classes.errors}`}>
                      {errors?.website?.message}
                    </p>
                  )}

                  <FormGroup
                    className={`customInputFilledDesign inputNumber input-box`}
                  >
                    <input
                      type="text"
                      {...register("social_media_account")}
                      id="social_media_account"
                      name="social_media_account"
                      placeholder=" "
                    />
                    <label htmlFor="social_media_account" >Social Media Account</label>
                  </FormGroup>
                  {errors?.social_media_account && (
                    <p className={`${classes.errors}`}>
                      {errors?.social_media_account?.message}
                    </p>
                  )}

                  <FormGroup
                    className={`customInputFilledDesign inputNumber input-box`}
                  >
                    <input
                      type="text"
                      {...register("contact_name")}
                      id="contact_name"
                      name="contact_name"
                      placeholder=" "
                    />
                    <label htmlFor="contact_name" >Contact Person Name</label>
                  </FormGroup>
                  {errors?.contact_name && (
                    <p className={`${classes.errors}`}>
                      {errors?.contact_name?.message}
                    </p>
                  )}

                  <FormGroup
                    className={`customInputFilledDesign  input-box inputNumber`}
                  >
                    <Controller
                      name="contact_number"
                      control={control}
                      render={({ field }) => (
                        <>
                          <PhoneInput
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value); // Update the form state
                              handlePhoneChange(value); // Update the local state
                            }}
                            inputProps={{
                              name: "contact_number",
                              id: "contact_number",
                              required: false,
                              autoFocus: false,
                              placeholder: "Contact Person Number",
                              autoComplete: "off", // Disable autocomplete
                            }}
                            inputStyle={{
                              width: "100%",
                            }}
                            sx={{
                              "& .special-label": {
                                display: "none", // Remove the text from special-label
                              },
                            }}
                          />
                          {/* <InputLabel htmlFor="contact_number">
                            Contact Person Phone Number
                          </InputLabel> */}
                        </>
                      )}
                    />
                  </FormGroup>
                  {errors?.contact_number && (
                    <p className={`${classes.errors}`}>
                      {errors?.contact_number?.message}
                    </p>
                  )}

                  <FormGroup
                    className={`customInputFilledDesign  input-box inputNumber`}
                  >
                    <input
                      type="text"
                      {...register("contact_position")}
                      id="contact_position"
                      name="contact_position"
                      placeholder=" "
                    />
                    <label htmlFor="contact_position">Contact Person Designation</label>
                  </FormGroup>
                  {errors?.contact_position && (
                    <p className={`${classes.errors}`}>
                      {errors?.contact_position?.message}
                    </p>
                  )}

                  <FormGroup
                    className={`customInputFilledDesign input-box inputNumber bg-white d-flex flex-row align-items-center justify-content-between`}
                  >
                    <Box component={"div"} sx={{ width: "calc(100% - 60px)" }}>
                      <input
                        className="d-none"
                        type="file"
                        {...register("contact_id")}
                        id="contact_id"
                        name="contact_id"
                        accept=".JPG, .jpg, .png"
                        onChange={(e) => handleFileChange(e.target.files)}
                        ref={fileInputRef}
                      />
                      <input type="text" value={selectedFileName} readOnly />
                      <label htmlFor="contact_id">
                        Contact Person ID Proof
                      </label>
                    </Box>
                    <Box component={"div"} sx={{ width: "50px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        component="span"
                        onClick={handleButtonClick}
                        sx={{
                          background: "#fff !important",
                          width: "40px",
                          height: "40px",
                          minWidth: "40px",
                          maxWidth: "40px",
                          borderRadius: "50%",
                          padding: "5px !important",
                        }}
                      >
                        <svg
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 7.25H3.5C2.90326 7.25 2.33097 7.48705 1.90901 7.90901C1.48705 8.33097 1.25 8.90326 1.25 9.5V18.5C1.25 19.0967 1.48705 19.669 1.90901 20.091C2.33097 20.5129 2.90326 20.75 3.5 20.75H12.5C13.0967 20.75 13.669 20.5129 14.091 20.091C14.5129 19.669 14.75 19.0967 14.75 18.5V9.5C14.75 8.90326 14.5129 8.33097 14.091 7.90901C13.669 7.48705 13.0967 7.25 12.5 7.25H11M11 4.25L8 1.25M8 1.25L5 4.25M8 1.25V14"
                            stroke="#757575"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </Box>
                  </FormGroup>

                  {errors?.contact_id && (
                    <p className={`${classes.errors}`}>
                      {errors?.contact_id?.message}
                    </p>
                  )}

                  <FormGroup className="submit-box mt-4">
                    <Box className="d-flex flex-wrap gap-2 gap-xxl-4">
                      <button
                        className="default-btn white-bg"
                        onClick={() => {
                          navigate("/loginv2");
                        }}
                      >
                        Cancel
                      </button>
                      <button className="default-btn green-bg" type="submit">
                        Next
                      </button>
                    </Box>
                  </FormGroup>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpContactDetails;
